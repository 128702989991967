<app-breadcrumb [title]="'Crear Lead'" [items]="['Crear Lead']" [active_item]="''"></app-breadcrumb>
<div class="container-fluid">
    <button *ngIf="user_type_id  == 2" (click)="open_modal()" class="btn btn-primary">Agregar +</button>
    <button *ngIf="user_type_id  != 2" (click)="open_modal_jefe()" class="btn btn-primary">Agregar +</button>
    <div *ngIf="leads.length > 0">
        <table class="table pulpa-datatable-responsive">
            <thead>
                <tr>
                    <th>Estado</th>
                    <th>Nombres</th>
                    <th>DNI</th>
                    <th>Fecha del creación</th>
                    <th>Modelo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lead of leads" class="tr-mobile">
                    <div class="imagen-container">
                        <img class="imagen-moto" src="{{lead.moto_imagen}}" alt="Imagen de {{lead.auto_modelo}}">
                    </div>

                    <td><span class="badge rounded-pill {{lead.bg_class}}">{{lead.estado_cliente | uppercase}}</span>
                    </td>

                    <td>{{lead.nombres}}</td>
                    <td>{{lead.dni}}</td>
                    <td>{{lead.created_time.substring(0,10)}}</td>
                    <td><img class="imagen-moto-desktop" src="{{lead.moto_imagen}}" alt="Imagen de moto {{lead.modelo}}">
                        <span>{{lead.modelo}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="leads.length == 0" class="m-t-20">
        <small>¡Actualmente no cuentas con leads!</small>
    </div>
</div>